export default {
  namespaced: true,
  state: {
    property: {},
  },
  mutations: {
    updateProperty(state, data) {
      state.property = data
    },
  },
}
