export default {
  namespaced: true,
  state: {
    temporarySocialToken: null,
    socialName: '',
    chosenSocialAccount: '',
  },
  mutations: {
    updateTemporarySocialToken(state, data) {
      state.temporarySocialToken = data
    },
    updateSocialName(state, data) {
      state.socialName = data
    },
    updateChosenSocialAccount(state, data) {
      state.chosenSocialAccount = data
    },
  },
}
