import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin,
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import vSelect from 'vue-select'
import VueCompositionAPI from '@vue/composition-api'
import '@core/scss/vue/libs/vue-select.scss'
// import * as io from 'socket.io-client'
// import VueSocketIO from 'vue-socket.io'

import { lowercase } from '@core/directives/lowercase'
import VueQuillEditor from 'vue-quill-editor'
// eslint-disable-next-line import/no-extraneous-dependencies
import Quill from 'quill'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/vue-select'
import '@/libs/toastification'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.bubble.css'

const defaultOptions = {
  theme: 'snow',
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ color: [] }], // dropdown with defaults from theme
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      [{ size: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ],
  },
}
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)
Vue.use(VueQuillEditor, defaultOptions)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('v-select', vSelect)
Vue.directive('lowercase', lowercase)

// Composition API
Vue.use(VueCompositionAPI)
// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: io('https://dev.gozayaan.com/'),
//   }),
// )
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
