/* eslint-disable no-nested-ternary,no-underscore-dangle */
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/libs/api'

export default {
  data() {
    return {
      debounce: null,
    }
  },
  methods: {
    durationToText(duration) {
      let formatText = ''
      if (duration) {
        const dn = moment.duration(duration)
        formatText += dn._data.days && dn._data.days >= 1 ? dn._data.days > 1 ? `${dn._data.days} days ` : `${dn._data.days} day ` : ''
        formatText += dn._data && dn._data.hours >= 1 ? dn._data.hours > 1 ? `${dn._data.hours} hours ` : `${dn._data.hours} hour ` : ''
        formatText += dn._data.minutes && dn._data.minutes >= 1 ? dn._data.minutes > 1 ? `${dn._data.minutes} mins ` : `${dn._data.minutes} min ` : ''
        return formatText.length ? formatText : duration
      }
      return duration
    },
    formatTimeFromString(value) {
      const duration = moment.duration(value)
      return moment.utc(duration.asMilliseconds()).format('H[h] m[m]').replace('0h', '')
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length
    },
    formatDateTime(dateString) {
      return dateString ? moment(dateString).format('DD MMM, hh:mm A') : 'N/A'
    },
    customDateFormat(dateString, format) {
      return moment(dateString).format(format)
    },
    formatDate(date) {
      return moment(date).format('DD MMM, Y')
    },
    formatDay(day) {
      return moment.utc(day).format('ddd, DD MMM, Y')
    },
    formatTime(date) {
      return moment.utc(date).format('hh:mm A')
    },
    paginate(page, callback) {
      callback()
    },
    checkDate(startDate, endDate) {
      const diff = moment(endDate).diff(moment(startDate), 'days')
      if (diff < 0) {
        return moment(startDate).add(14, 'days').format('YYYY-MM-DD')
      }
      return endDate
    },
    formatClassName(status) {
      if (status === 'PENDING' || status === 'BOOKING_HOLD' || status === 'RESUBMIT') {
        return 'light-warning'
      }
      if (status === 'BOOKING_FAILED' || status === 'FAILED' || status === 'REJECTED' || status === 'TICKETING_FAILED' || status === 'CANCELLED') {
        return 'light-danger'
      }
      if (status === 'BOOKING_SUCCESS' || status === 'REFUNDED' || status === 'INCOMPLETE') {
        return 'light-info'
      }
      if (status === 'TICKET_ISSUED' || status === 'TICKETING_SUCCESS' || status === 'SUCCESS' || status === 'APPROVED') {
        return 'light-success'
      }
      return 'light-dark'
    },
    debounceSearch(offset, callback) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        callback()
      }, 500)
    },
    bookingID(product, data) {
      if (product === 'FLIGHT') {
        return data.flight_booking_reference.booking_id
      }
      if (product === 'HOTEL') {
        return data.booking_reference.go_zayaan_reference
      }
      if (product === 'BUS') {
        return data.bus_reference.booking_id
      }
      if (product === 'TOUR') {
        return data.tour_reference.booking_id
      }
      return 'N/A'
    },
    getSupplierSuggestion(query, type) {
      const supplierType = type ? `&&supplier_type=${type}` : ''
      return api.getData(`user_journey/admin_services/supplier_list/?search=${query}${supplierType}`, true)
    },
    notificationMessage(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
          text,
        },
      }, { position: 'bottom-right', timeout: 5000, pauseOnHover: true })
    },
    formatPlatform(platform) {
      switch (platform) {
        case 'GZ_WEB':
          return 'Website'
        case 'BKASH_APP':
          return 'bKash App'
        case 'UPAY_APP':
          return 'UPAY App'
        default:
          return platform
      }
    },
  },
}
