import api from '@/libs/api'
import helper from './helper'

export default {
  mixins: [helper],
  methods: {
    clearUserData() {
      this.$store.commit('auth/updateToken', null)
      this.$store.commit('auth/updateExpiry', null)
      this.$store.commit('auth/updateAccountInfo', {})
      this.$store.commit('auth/updatePermissions', [])
      this.$store.commit('auth/updateRoleStatus', false)
      this.$store.commit('auth/updateAbilities', [])
    },
    isUserValid() {
      return api.getData('account_management/user_account/', true)
        .then(response => response.data.status === 200).catch(() => false)
    },
    userLoggedIn(store) {
      if (store) {
        return store.getters['auth/userLoggedIn']
      }
      return this.$store.getters['auth/userLoggedIn']
    },
    setUserData(response) {
      this.$store.commit('auth/updateToken', `Token ${response.token}`)
      this.$store.commit('auth/updateExpiry', response.expiry)
      this.$store.commit('auth/updatePermissions', response.user.groups)
      this.$store.commit('auth/updateAccountInfo', response.user.account_info)
      this.$router.replace('/').then(() => {
        this.notificationMessage('success', 'CoffeeIcon', 'Welcome to Go Zayaan Admin', 'You have successfully logged in. Now you can start to explore!')
      })
    },
  },
}
